import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Img from 'gatsby-image'

function Error(props) {
  const siteTitle = get(props, 'data.site.siteMetadata.title')

  return (
    <Layout location={props.location}>
      <Helmet title={siteTitle} />

      <section className="hero hero--full site-wide grid-container grid-container--half grid-container--no-indent-right">
        <div className="grid-column">
          <div>
            <h1 className="h2-styling">Something just doesn't seem right...</h1>

            <a href="/" className="h5-styling">
              Get back on it!
            </a>
          </div>
        </div>

        <div className="grid-column">
          <img
            src={'/alcohol-free.jpg'}
            alt="Alcohol free?!"
            className="hero__image"
          />
        </div>
      </section>
    </Layout>
  )
}

export default Error
